import { event } from '@abyss/web/tools/event';
import { adobeAnalytics } from '@abyss/web/tools/event/listeners/adobeAnalytics';
import { newRelicAnalytics } from '@abyss/web/tools/event/listeners/newRelicAnalytics';
import { config } from '@abyss/web/tools/config';
import { adobeTags } from './tags/adobe';
import { newRelicTags } from './tags/newRelic';
import { init as initApm } from "@elastic/apm-rum";

export const registerAnalytics = data => {

  var pageName = window.location.pathname;
  event.listener(
    adobeAnalytics({
      id: 'adobe-analytics-abyss',
      enabled: config('ANALYTICS_TRACKING'),
      logging: config('ANALYTICS_LOGGING'),
      events: adobeTags,
      metadata: data,
    })
  );
  event.listener(
    newRelicAnalytics({
      enabled: config('ANALYTICS_TRACKING'),
      logging: config('ANALYTICS_LOGGING'),
      events: newRelicTags,
      metadata: data,
    })
  );

  event.listener(
    initApm({
      serviceName: config('ELASTIC_APM_SERVICE_NAME'),
      serverUrl: config('ELASTIC_APM_SERVER_URL'),    
      environment: config('FEATURES_ENV'),    
      pageLoadTransactionName: pageName,
      pageLoadSampled: true,
      logLevel: 'trace',
      active: true	            
    })
  );
};
